import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import {
  FETCH_LANGUAGES,
  FETCH_TRANSFER_WISE_CONFIG,
  FETCH_TRANSLATIONS,
  SET_APP_VERSION,
  SET_CURRENT_ROUTE,
  SET_SITE
} from '@/store/keys';

import { mainRoutes } from '@/router/main';
import { exchangeRoutes } from '@/router/exchange';
import { posRoutes } from '@/router/pos';

store.commit(SET_SITE, '');
const host = window.location.host;
let routes = mainRoutes;

// if (host.indexOf('exchange') !== -1) {
store.commit(SET_SITE, 'reflextrader');
routes = exchangeRoutes;
// }
if (host.indexOf('posercart') !== -1) {
  store.commit(SET_SITE, 'ecom');
  routes = posRoutes;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

router.beforeEach((to, from, next) => {
  // Set current route
  if (['login', 'signup', 'recover-password'].indexOf(to.name) === -1) {
    store.commit(SET_CURRENT_ROUTE, to.fullPath);
  }
  // Title
  const title = to.meta?.title;
  if (title) {
    document.title = title;
  }
  const version = '2.0.4';
  const appVersion = store.getters.appVersion;
  if (appVersion === null || appVersion !== version) {
    store.commit(SET_APP_VERSION, version);
    // Update localstorage with updated data
    store.dispatch(FETCH_LANGUAGES).catch(() => {});
    store.dispatch(FETCH_TRANSLATIONS).catch(() => {});
    // Get transferwise config
    store.dispatch(FETCH_TRANSFER_WISE_CONFIG).catch(() => {});
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      return next({ name: 'login' });
    }
  }
  next();
});
export default router;
